import React from 'react';
import '../promotion/promotion.css'
import close from '../../custom_views/custom_icons/close.svg'
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import BtnLoader from '../loaders/BtnLoader';
import { declension, getRequestUrl, getHttpParams } from '../../utils/Utils';
import { getRequestParams } from '../insta_custom_views/utils_android/CookieHelper';
import NotEnoughtCoins from '../tooltips/NotEnoughtCoins';
import { getEnabledBtnStyle } from '../../utils/PhotoItemHelper';

var strings = getLocalizedStrings()
class BuyFollowers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowFollowersTooltip: false,
            isShowNotEnought: false,
            isLoading: false,

            coinsPrice: 100,
            declention: strings.COIN_5,
            followersCount: 10,
            notEnoughtCoins: (strings.NOT_ENOUGHT_COINS_TOOLTIP + " " + this.props.count_coins + " " + declension(this.props.count_coins, strings.COIN, strings.COIN_2, strings.COIN_5)),

            isPrivate: false,
            //isUpdateCaptcha : false,



        }
        this.toogleFollowersTooltip = this.toogleFollowersTooltip.bind(this);
        this.toogleNotEnought = this.toogleNotEnought.bind(this);
        this.followersChanged = this.followersChanged.bind(this);
        this.buyFollowers = this.buyFollowers.bind(this);

    }
    componentDidUpdate(prevProps) {
        if (prevProps != this.props) {
            this.setState({ notEnoughtCoins: (strings.NOT_ENOUGHT_COINS_TOOLTIP + " " + this.props.count_coins + " " + declension(this.props.count_coins, strings.COIN, strings.COIN_2, strings.COIN_5)) })
            if (this.props.countProgressCoins >= 0 && this.props.allProgressCoins >= 0) {
                var followersProgress = parseInt(((this.props.allProgressCoins - this.props.countProgressCoins) / 10)) + " " + strings.FROM + " " + parseInt((this.props.allProgressCoins / 10))
                this.setState({ followersProgress: followersProgress })
            }
        }
    }
    followersChanged(event) {
        if (event) {
            var followersCount = event.target.value
            var price = (parseInt(followersCount) * 10)
            if (followersCount == "" || followersCount < 0) {
                price = 0
            }
            var declCoins = declension(price, strings.COIN, strings.COIN_2, strings.COIN_5)

            this.setState({ followersCount: followersCount, coinsPrice: price, declention: declCoins, isShowNotEnought: false })
        }
    }
    toogleFollowersTooltip() {
        this.setState({ isShowFollowersTooltip: !this.state.isShowFollowersTooltip })
    }
    toogleNotEnought() {
        this.setState({ isShowNotEnought: !this.state.isShowNotEnought })
    }

    buyFollowers() {
        if (parseInt(this.state.coinsPrice) > parseInt(this.props.count_coins)) {
            this.toogleNotEnought()
        } else {
            this.setState({ isLoading: true })
            this.buyFollowersRequest()
        }
    }

    buyFollowersRequest = () => {
        var params = getRequestParams()
        params.add_coins = this.state.coinsPrice
        params.photo_url = this.props.user.profile_pic_url2
        params.biography = this.props.user.biography;
        //params.timestamp = timestamp
        fetch(getRequestUrl() + "buyFollowers", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == "1" || responseData.response == "2") {
                    this.props.updateMoney(0, -this.state.coinsPrice)
                    this.props.updateFollowersProgress(this.state.coinsPrice)
                } else if (responseData.response == -100) {
                    this.props.sessionError()
                }
                this.setState({ isLoading: false, isShowFollowersTooltip: false })
                console.log("buyFollowers = " + JSON.stringify(responseData))

            })
    }
    render() {
        return (
            <div className="account-score">
                <div className="account-score__counts">
                    <div className="account-score__count">
                        <span className="account-score__value">{this.props.user.media_count}</span> {strings.POSTS}
                    </div>
                    <div className="account-score__count">
                        <span className="account-score__value">{this.props.user.following_count}</span> {strings.FOLLOWING}
                    </div>
                    <div className="account-score__count">
                        <span className="account-score__value">{this.props.user.follower_count}</span> {strings.FOLLOWERS}
                    </div>
                </div>
                <div className="account-score__add">
                    <span>
                        <div style={this.state.isShowFollowersTooltip ? { background: '#38343E' } : {}} onClick={this.toogleFollowersTooltip}
                            className="btn_purple account-score__btn hover">{strings.GET_FOLLOWERS}</div>
                        {this.state.isShowFollowersTooltip && <div className="account-score__tooltip add-followers-tooltip">
                            <i className="total-tooltip__arrow account-score__tooltip-arrow"></i>
                            <div className="total-tooltip__wrapper account-score__tooltip-wrap">
                                <img src={close} onClick={this.toogleFollowersTooltip} className="total-tooltip__close hover" />

                                <form className="add-subscribe-form" >
                                    <div className="add-subscribe-form__item">
                                        <label className="tooltip add-subscribe-form__label">{strings.COUNT_FOLLOWERS}
                                            <input onChange={this.followersChanged} type="number" min="1" className="input add-subscribe-form__input" name="Count" value={this.state.followersCount} />
                                        </label>
                                        {this.state.coinsPrice < 100 && <div style={{ margin: '12px 0 0 0', fontSize: '14px' }}>{strings.MIN_ORDER_FOLLOWERS}</div>}
                                    </div>
                                    <div className="add-subscribe-form__item bot_m_0">
                                        <label className="add-subscribe-form__label jsutify_space">{strings.PRICE_FOLLOWERS}
                                            <span className="tooltip rate-content__tooltip left_m_0">
                                                <p className="follower_price">{this.state.coinsPrice} {this.state.declention}</p>
                                            </span>
                                        </label>

                                        {this.state.isShowNotEnought && <NotEnoughtCoins text={this.state.notEnoughtCoins} />}
                                        {/* <span className="tooltip add-subscribe-form__tooltip">
                                            <div className="tooltip__container tooltip-top tooltip-preview">
                                                <i ng-click="followersTooltip()" className="tooltip__close tooltip-preview__close"></i>
                                                <i className="tooltip__arrow"></i>
                                                <img className="tooltip-preview__photo" src="https://placehold.it/160x160" alt="Photo"/>
                                                <h4 className="tooltip-preview__username">{this.props.user.username}</h4>
                                                <p>{this.props.user.biography}</p>
                                            </div>
                                        </span> */}
                                    </div>
                                    <div className="add-subscribe-form__item">

                                        <div onClick={this.buyFollowers} style={(this.state.isLoading || this.state.coinsPrice < 100) ? getEnabledBtnStyle() : {}} className="btn_purple add-subscribe-form__btn hover">{strings.CREATE_PROMOTION}
                                            {this.state.isLoading && <BtnLoader />}
                                        </div>
                                        <div style={{margin : '16px 0 0 0'}}>
                                            <b>Перед заказом обязательно настройте аккаунт в Instagram:</b><br/><br/>

                                            1. Перейдите в "Настройки и действия"<br/>

                                            2. Выберите "Пригласить друзей и подписаться"<br/>

                                            3. Отключите функцию "Пометить для проверки"
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>}
                    </span>

                    {this.props.allProgressCoins > 0 && <span className="account-score__in-progress">{strings.IN_PROCCESS} <b>{this.state.followersProgress}</b></span>}

                </div>

            </div>

        );
    }
}
export default BuyFollowers;
